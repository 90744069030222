import { validateEmail } from '../../modules/components/validateEmail'

export const SET_REQUEST = 'SET_REQUEST'

export const setRequest = (data) => {
    return async (dispatch) => {
        // clear message
        dispatch({
            type: SET_REQUEST,
            message: ''
        })

        // verify data
        if (!!data.type && !!data.description && !!data.offer && validateEmail(data.email)) {

            const url = 'https://data.powershotz.com/php/requests.php'

            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
            })
                .then((res) => {
                    if (res.ok) {
                        dispatch({
                            type: SET_REQUEST,
                            message: 'Successfully sent!',
                        })
                    } else {
                        dispatch({
                            type: SET_REQUEST,
                            message: 'Error. Please try again.',
                        })
                    }
                })
                .catch((e) => {
                    console.log(`Error setting request\n${e.message}`)
                    dispatch({
                        type: SET_REQUEST,
                        message: `Error setting request\n${e.message}`
                    })
                })
            return true
        } else {
            return false
        }
    }
}
