import React from 'react'
import '../../css/loader.css'

export const AppLoader = () => {
    return (
        <section className='app-loader'>
            <div className='bouncing-loader'>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </section>
    )
}

export const SectionLoader = () => {
    return (
        <section className='section-loader'>
            <div className='bouncing-loader'>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </section>
    )
}