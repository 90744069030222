export const GET_HELP_VIDEOS = 'GET_HELP_VIDEOS'

export const getHelpVideos = (data) => {
    return async (dispatch) => {
        const url = 'https://data.powershotz.com/php/getHelpVideos.php'
        const helpVideos = []

        fetch(url, {
            method: 'POST',
        })
            .then(res => res.json())
            .then(data => data.map(i => helpVideos.push(i)))
            .then(() => {
                dispatch({
                    type: GET_HELP_VIDEOS,
                    data: helpVideos,
                })
            })
            .catch((e) => {
                console.log(`Error getting help videos\n${e.message}`)
                dispatch({
                    type: GET_HELP_VIDEOS,
                    message: `Error getting help videos\n${e.message}`
                })
            })
    }
}
