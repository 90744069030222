import { combineReducers } from 'redux'
// import { firestoreReducer } from 'redux-firestore'
// import { firebaseReducer } from 'react-redux-firebase'

import requestsReducer from './requests'
import castingCallsReducer from './castingCalls'
import helpVideosReducer from './helpVideos'
import powershopzStoresReducer from './powershopzStores'
import productsReducer from './products'
import ordersReducer from './orders'

const rootReducer = combineReducers({
  requests: requestsReducer,
  castingCalls: castingCallsReducer,
  helpVideos: helpVideosReducer,
  powershopzStores: powershopzStoresReducer,
  products: productsReducer,
  orders: ordersReducer,
  // firestore: firestoreReducer, //data
  // firebase: firebaseReducer, //auth
})

export default rootReducer
