import { SET_REQUEST } from '../actions/requests'

const initialState = { message: null }

const requestsReducer = (state = initialState, action) => {
    if (action.type === SET_REQUEST) {
        return { message: action.message }
    } else {
        return state
    }
}

export default requestsReducer
