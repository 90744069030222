export const listReducer = (state, action) => {
  const id = action.id
  const checked = action.checked

  switch (action.type) {
    case 'ADD_ITEM':
      return [...state, { id, checked: false }]

    case 'CHANGE_ITEM':
      const array = state.filter((item) => item.id !== id)
      return [...array, { id, checked: !checked }]

    case 'MOVE_ITEM':
      const arr = state.filter((item) => item.id !== id)
      return [...arr, { id: action.id, checked: true }]

    case 'REMOVE_ITEM':
      return state.filter((item) => item.id !== id)

    case 'REMOVE_ALL':
      return []

    default:
      return state
  }
}
