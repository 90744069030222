export const GET_POWERSHOPZ_STORES = 'GET_POWERSHOPZ_STORES'

export const getPowershopzStores = () => {
    return async (dispatch) => {
        const url = 'https://data.powershotz.com/php/getPowershopzStores.php'
        const stores = []

        fetch(url, {
            method: 'POST',
        })
            .then(res => res.json())
            .then(data => data.map(i => stores.push(i)))
            .then(() => {
                dispatch({
                    type: GET_POWERSHOPZ_STORES,
                    data: stores,
                })
            })
            .catch((e) => console.log(`Error getting Powershopz stores\n${e.message}`))
    }
}
