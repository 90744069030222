import dayjs from 'dayjs'

export const GET_ORDER = 'GET_ORDER'
export const GET_CUSTOMER_ORDERS = 'GET_CUSTOMER_ORDERS'

const createArray = (items) => {
    const arr = []
    const split = items.split(',')
    split.map(i => arr.push(i))
    return arr
}

const formatOrder = (order) => {
    const data = {
        ...order,
        created_at: dayjs(order.created_at).format('MMMM DD, YYYY'),
        unformattedDate: order.created_at,
        total: parseFloat(order.total),
        items: createArray(order.items),
        paid: parseInt(order.paid),
        sent: parseInt(order.sent),
    }
    return data
}

const formatCustomerOrder = (order) => {
    const data = {
        ...order,
        created_at: dayjs(order.created_at).format('MMMM DD, YYYY'),
        total: parseFloat(order.total),
        paid: parseInt(order.paid),
        sent: parseInt(order.sent),
    }
    return data
}

export const getOrder = (orderNumber) => {
    return async (dispatch) => {
        const url = `https://data.powershotz.com/php/getOrder.php?orderNumber=${orderNumber}`

        fetch(url)
            .then(res => res.json())
            .then(data => {
                dispatch({
                    type: GET_ORDER,
                    order: formatOrder(data),
                })
            })
            .catch((e) => {
                console.log(`Error finding order\n${e.message}`)
            })
    }
}

export const getCustomerOrders = (email) => {
    return async (dispatch) => {
        const url = 'https://data.powershotz.com/php/getCustomerOrders.php'
        const data = { email }
        const orders = []

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(data => {
                data.map(i => orders.push(formatCustomerOrder(i)))
            })
            .then(() => {
                dispatch({
                    type: GET_CUSTOMER_ORDERS,
                    customerOrders: orders,
                })
            })
            .catch((e) => {
                console.log(`Error finding customer orders\n${e.message}`)
            })
    }
}

export const updatePaymentId = (orderId, paymentId) => {
    return async (dispatch, getState) => {

        const data = {
            orderId: orderId,
            paymentId: paymentId,
        }

        const url = 'https://data.powershotz.com/php/updatePaymentId.php'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        })
            .then(() => {
                console.log('success updating paymentId')
            })
            .catch((e) => {
                alert(`Error updating paymentId\n${e.message}`)
            })
    }
}
