// import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './css/styles.css'
import App from './App'
import { unregister } from './serviceWorker'
import ScrollToTop from './modules/components/ScrollToTop'
// import reportWebVitals from './reportWebVitals'

unregister()

const root = createRoot(document.getElementById('root'))

root.render(
  // <StrictMode>
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>
  // </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// TODO: when debugging, uncomment strictmode and reportwebvitals
// reportWebVitals(console.log)
// serviceWorker.unregister()
