export const cartReducer = (state, action) => {
  const id = action.id
  const checked = action.checked

  switch (action.type) {
    case 'ADD_CART_ITEM':
      return [...state, { id, checked: false }]

    case 'CHANGE_CART_ITEM':
      const array = state.filter((item) => item.id !== id)
      return [...array, { id, checked: !checked }]

    case 'REMOVE_CART_ITEM':
      return state.filter((item) => item.id !== id)

    case 'REMOVE_CART_ITEMS':
      return state.filter((item) => item.checked === true)

    case 'REMOVE_CART_ALL':
      return []

    default:
      return state
  }
}
