import React, { useContext, useState } from 'react'
import { bubble as Menu } from 'react-burger-menu'
import { NavLink, Link } from 'react-router-dom'
import '../css/navbar.css'
import logo from '../images/logo.svg'
import logo2 from '../images/logo2.svg'
import listImg from '../images/list.webp'
import cartImg from '../images/cart.png'
import { CartContext } from './contexts/CartContext'
import { ListContext } from './contexts/ListContext'
import { BsLink45Deg } from '@react-icons/all-files/bs/BsLink45Deg'
import { FiHome } from '@react-icons/all-files/fi/FiHome'
import { BiHomeAlt } from '@react-icons/all-files/bi/BiHomeAlt'
import { FiDisc } from '@react-icons/all-files/fi/FiDisc'
import { BsQuestionSquare } from '@react-icons/all-files/bs/BsQuestionSquare'
import { ImVideoCamera } from '@react-icons/all-files/im/ImVideoCamera'
import { TiShoppingCart } from '@react-icons/all-files/ti/TiShoppingCart'
import { ImWoman } from '@react-icons/all-files/im/ImWoman'
import { RiFileList3Line } from '@react-icons/all-files/ri/RiFileList3Line'
import { GoComment } from '@react-icons/all-files/go/GoComment'
import { ImFileVideo } from '@react-icons/all-files/im/ImFileVideo'
import { BsInfoCircle } from '@react-icons/all-files/bs/BsInfoCircle'
import { isMobile } from 'react-device-detect'
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch"

// ref: https://www.npmjs.com/package/react-burger-menu

// TODO: https://www.npmjs.com/package/react-contexify


var styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '36px',
    top: '36px',
  },
  bmBurgerBars: {
    background: '#ad35b8',
    boxShadow: '1px 2px 3px black',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    top: 10,
    left: 10,
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
  },
  bmMenu: {
    background: '#373a47',
    padding: isMobile ? '2em 1.5em 0 1.5em' : '2.5em 2.5em 0 1.5em',
    overflow: 'auto',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
    justifyContent: 'space-between',
    height: 'auto',
  },
  bmItem: {
    display: 'block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
  counter: {
    backgroundColor: 'white',
    color: 'var(--backgroundColor)',
    fontSize: '12px',
    fontWeight: 'bold',
    borderRadius: '50px',
    height: '24px',
    width: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    border: '1px solid var(--backgroundColor)',
    transform: 'translateY(-25px)',
    margin: '0 auto',
  },
  menuItem: {
    fontSize: '1.25rem',
    display: 'block',
    margin: isMobile ? '15px 0' : '10px 0',
    color: '#bdc3c7',
  },
  logo: {
    borderBottom: '2px solid #bdc3c7',
    paddingBottom: 30,
  },
  navLinks: {
    marginTop: 60,
  },
  home: {
    color: '#ad35b8',
    padding: '20px',
    position: 'fixed',
    top: 15,
    right: 10,
  },
  cart: {
    color: '#ad35b8',
    padding: '20px',
    position: 'fixed',
    top: 15,
    right: 60,
  },
  search: {
    color: '#ad35b8',
    padding: 20,
    position: 'fixed',
    top: 15,
    right: isMobile ? 110 : 60,
  },
}

const activestyle = {
  textDecorationLine: 'none',
  color: '#0074DF',
  display: 'block',
  fontSize: '1.35rem',
  margin: isMobile ? '15px 0' : '10px 0',
}

const BurgerMenu = () => {
  const { cart } = useContext(CartContext)
  const { list } = useContext(ListContext)

  const [state, setState] = useState({ menuOpen: false })

  let itemsInCart = 0
  cart.map((item) => {
    return !item.checked && itemsInCart++
  })

  let itemsInList = 0
  list.map(() => {
    return itemsInList++
  })

  const handleStateChange = (state) => {
    setState({ menuOpen: state.isOpen })
  }

  const closeMenu = () => {
    setState({ menuOpen: false })
  }

  return (
    <div>
      {/* home button */}
      <Link to='/' title='Home'>
        <BiHomeAlt
          size={30}
          style={styles.home}
        />
      </Link>

      {/* cart button for mobile */}
      {isMobile && <Link to="/cart" title='Cart'      >
        <TiShoppingCart
          size={32}
          style={styles.cart}
        />
      </Link>}

      {/* search button */}
      <Link to='/search'>
        <AiOutlineSearch size={32} style={styles.search} />
      </Link>

      {/* menu */}
      <div className="navbar" id="outer-container">
        <Menu
          styles={styles}
          width={263}
          isOpen={state.menuOpen}
          onStateChange={(state) => handleStateChange(state)}
        >
          <main id="page-wrap">
            <div>
              <Link to="/" style={{ color: '#bdc3c7' }} onClick={closeMenu}>
                <img
                  src={logo}
                  id="logo"
                  className="logo"
                  alt="Powershotz.com logo"
                />
                <img
                  src={logo2}
                  id="logo2"
                  className="logo2"
                  alt="Powershotz.com logo"
                />
                <span id="powershotz" style={styles.logo}>
                  Powershotz
                </span>
              </Link>

              <div style={styles.navLinks}>
                <NavLink
                  to="/"
                  style={({ isActive }) => isActive ? activestyle : styles.menuItem}
                  onClick={closeMenu}
                >
                  <FiHome
                    size={20}
                    style={{
                      marginRight: 15,
                    }}
                  />
                  Home
                </NavLink>

                <NavLink
                  to="/search"
                  style={({ isActive }) => isActive ? activestyle : styles.menuItem}
                  onClick={closeMenu}
                >
                  <AiOutlineSearch
                    size={20}
                    style={{
                      marginRight: 15,
                    }}
                  />
                  Search
                </NavLink>

                <NavLink
                  to="/videos"
                  style={({ isActive }) => isActive ? activestyle : styles.menuItem}
                  onClick={closeMenu}
                >
                  <ImVideoCamera
                    size={20}
                    style={{
                      marginRight: 15,
                    }}
                  />
                  Videos
                </NavLink>

                <NavLink
                  to="/photos"
                  style={({ isActive }) => isActive ? activestyle : styles.menuItem}
                  onClick={closeMenu}
                >
                  <ImWoman
                    size={20}
                    style={{
                      marginRight: 15,
                    }}
                  />
                  Models
                </NavLink>

                <NavLink
                  to="/dvd"
                  style={({ isActive }) => isActive ? activestyle : styles.menuItem}
                  onClick={closeMenu}
                >
                  <FiDisc
                    size={20}
                    style={{
                      marginRight: 15,
                    }}
                  />
                  DVDs
                </NavLink>

                <NavLink
                  to="/cart"
                  style={({ isActive }) => isActive ? activestyle : styles.menuItem}
                  onClick={closeMenu}
                >
                  <TiShoppingCart
                    size={20}
                    style={{
                      marginRight: 15,
                    }}
                  />
                  Cart {itemsInCart > 0 && <span>({itemsInCart})</span>}
                </NavLink>

                <NavLink
                  to="/mylist"
                  style={({ isActive }) => isActive ? activestyle : styles.menuItem}
                  onClick={closeMenu}
                >
                  <RiFileList3Line
                    size={20}
                    style={{
                      marginRight: 15,
                    }}
                  />
                  My List
                </NavLink>

                <NavLink
                  to="/helpVidz"
                  style={({ isActive }) => isActive ? activestyle : styles.menuItem}
                  onClick={closeMenu}
                >
                  <ImFileVideo
                    size={20}
                    style={{
                      marginRight: 15,
                    }}
                  />
                  Help Vidz
                </NavLink>

                <NavLink
                  to="/contact"
                  style={({ isActive }) => isActive ? activestyle : styles.menuItem}
                  onClick={closeMenu}
                >
                  <GoComment
                    size={20}
                    style={{
                      marginRight: 15,
                    }}
                  />
                  Contact
                </NavLink>

                <NavLink
                  to="/faqs"
                  style={({ isActive }) => isActive ? activestyle : styles.menuItem}
                  onClick={closeMenu}
                >
                  <BsQuestionSquare
                    size={20}
                    style={{
                      marginRight: 15,
                    }}
                  />
                  FAQs
                </NavLink>

                <NavLink
                  to="/about"
                  style={({ isActive }) => isActive ? activestyle : styles.menuItem}
                  onClick={closeMenu}
                >
                  <BsInfoCircle
                    size={20}
                    style={{
                      marginRight: 15,
                    }}
                  />
                  About
                </NavLink>

                <a href="https://powershotz.com">
                  <BsLink45Deg
                    size={20}
                    style={{
                      marginRight: 15,
                    }}
                  />
                  New Site!
                </a>
              </div>
            </div>
          </main>
        </Menu>

        {/* bottom nav buttons */}
        {!isMobile &&
          <>
            <Link to="/cart">
              <div src={cartImg} title="Shopping Cart" className="cart">
                {itemsInCart > 0 && <div style={styles.counter}>{itemsInCart}</div>}
              </div>
            </Link>

            <Link to="/mylist">
              <div src={listImg} title="My List" className="list">
                {itemsInList > 0 && <div style={styles.counter}>{itemsInList}</div>}
              </div>
            </Link>

            <div
              className="top"
              title="top of page"
              onClick={() => {
                window.scrollTo(0, 0)
              }}
            ></div>
            <div
              className="bottom"
              title="bottom of page"
              onClick={() => {
                window.scrollTo(0, document.body.scrollHeight)
              }}
            ></div>
          </>
        }
      </div>
    </div>
  )
}

export default BurgerMenu
