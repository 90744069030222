import { GET_CASTING_CALLS } from '../actions/castingCalls'

const initialState = {
    data: null,
}

const castingCallsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CASTING_CALLS:
            return {
                data: action.data,
            }
        default:
            return state
    }
}

export default castingCallsReducer
