import { GET_POWERSHOPZ_STORES } from '../actions/powershopzStores'

const initialState = {
    stores: null,
}

const powershopzStoresReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_POWERSHOPZ_STORES:
            return {
                stores: action.data,
            }
        default:
            return state
    }
}

export default powershopzStoresReducer
