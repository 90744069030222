import dayjs from 'dayjs'

export const FIND_PRODUCT = 'FIND_PRODUCT'
export const CART_PRODUCTS = 'CART_PRODUCTS'
export const FETCH_PUBLISHED_DOWNLOADABLE = 'FETCH_PUBLISHED_DOWNLOADABLE'
export const FETCH_PUBLISHED_SHIPPABLE = 'FETCH_PUBLISHED_SHIPPABLE'

const createArray = (tags) => {
    const arr = []
    const split = tags.split(',')
    split.map(i => arr.push(i))
    return arr
}

const formatProductData = (prod) => {
    const data = {
        ...prod,
        date: dayjs(prod.date).format('MMMM DD, YYYY'),
        unformattedDate: prod.date,
        price: parseFloat(prod.price),
        salePrice: parseFloat(prod.salePrice),
        tags: createArray(prod.tags),
        imageArray: createArray(prod.imageArray),
        canBundle: parseInt(prod.canBundle),
        checkLink: parseInt(prod.checkLink),
        displayOrder: parseInt(prod.displayOrder),
        hosted: parseInt(prod.hosted),
        isFlatRate: parseInt(prod.isFlatRate),
        isPublished: parseInt(prod.isPublished),
        quantity: parseInt(prod.quantity),
        sale: parseInt(prod.sale),
        shippingIncluded: parseInt(prod.shippingIncluded),
        sold: parseInt(prod.sold),
        weight: parseFloat(prod.weight),
    }
    return data
}

const formatProductData2 = (prod) => {
    const data = {
        ...prod,
        date: dayjs(prod.date).format('MMMM DD, YYYY'),
        unformattedDate: prod.date,
        price: parseFloat(prod.price),
        salePrice: parseFloat(prod.salePrice),
        sale: parseInt(prod.sale),
    }
    return data
}

export const findProduct = (id) => {
    return async (dispatch) => {
        const url = 'https://data.powershotz.com/php/findProduct.php'
        const data = { id }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(data => dispatch({
                type: FIND_PRODUCT,
                foundProduct: formatProductData(data),
            }))
            .catch((e) => {
                console.log(`Error finding product\n${e.message}`)
            })
    }
}

export const cartProducts = (ids) => {
    return async (dispatch) => {
        const url = 'https://data.powershotz.com/php/cartProducts.php'
        const data = { ids }
        const products = []

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(data => {
                data.map(i => products.push(i))
            })
            .then(() => {
                dispatch({
                    type: CART_PRODUCTS,
                    cartProducts: products,
                })
            })
            .catch((e) => {
                console.log(`Error finding cart product\n${e.message}`)
            })
    }
}

// fetch published Powershopz downloadable products 50
export const fetchPublishedDownloadable = () => {
    return async (dispatch) => {
        const url = 'https://data.powershotz.com/php/shopz/fetchPublishedDownloadable.php'
        const products = []

        fetch(url, {
            method: 'POST',
        }).then(res => res.json())
            .then(data => data.map(i => products.push(formatProductData2(i))))
            .then(() => {
                dispatch({
                    type: FETCH_PUBLISHED_DOWNLOADABLE,
                    products: products,
                })
            })
            .catch((e) => console.log(`Error fetching shopz downloadable products\n${e.message}`))
    }
}

// fetch published Powershopz shippable products 50
export const fetchPublishedShippable = () => {
    return async (dispatch) => {
        const url = 'https://data.powershotz.com/php/shopz/fetchPublishedShippable.php'
        const products = []

        fetch(url, {
            method: 'POST',
        }).then(res => res.json())
            .then(data => data.map(i => products.push(formatProductData2(i))))
            .then(() => {
                dispatch({
                    type: FETCH_PUBLISHED_SHIPPABLE,
                    products: products,
                })
            })
            .catch((e) => console.log(`Error fetching shopz shippable products\n${e.message}`))
    }
}
