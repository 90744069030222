export const GET_CASTING_CALLS = 'GET_CASTING_CALL'

export const getCastingCalls = () => {
    return async (dispatch) => {
        const url = 'https://classic.powershotz.com/php/getCastingCalls.php'
        const castingCalls = []

        fetch(url, {
            method: 'POST',
        })
            .then(res => res.json())
            .then(data => data.map(i => castingCalls.push(i)))
            .then(() => {
                dispatch({
                    type: GET_CASTING_CALLS,
                    data: castingCalls,
                })
            })
            .catch((e) => console.log(`Error getting casting call\n${e.message}`))
    }
}
