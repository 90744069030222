import { GET_ORDER, GET_CUSTOMER_ORDERS } from '../actions/orders'

const initialState = {
    foundOrder: {},
    customerOrders: []
}

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDER:
            return {
                ...state,
                foundOrder: action.order,
            }
        case GET_CUSTOMER_ORDERS:
            return {
                ...state,
                customerOrders: action.customerOrders,
            }
        default:
            return state
    }
}

export default ordersReducer