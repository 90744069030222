import {
    FIND_PRODUCT,
    CART_PRODUCTS,
    FETCH_PUBLISHED_DOWNLOADABLE,
    FETCH_PUBLISHED_SHIPPABLE,
} from '../actions/products'

const initialState = {
    foundProduct: {},
    cartProducts: [],
    downloadable: [],
    shippable: [],
}

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FIND_PRODUCT:
            return {
                ...state,
                foundProduct: action.foundProduct,
            }
        case FETCH_PUBLISHED_DOWNLOADABLE:
            return {
                ...state,
                downloadable: action.products,
            }
        case FETCH_PUBLISHED_SHIPPABLE:
            return {
                ...state,
                shippable: action.products,
            }
        case CART_PRODUCTS:
            return {
                ...state,
                cartProducts: action.cartProducts,
            }
        default:
            return state
    }
}

export default productsReducer