import { GET_HELP_VIDEOS } from '../actions/helpVideos'

const initialState = {
    data: null,
}

const helpVideosReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HELP_VIDEOS:
            return {
                ...state,
                data: action.data,
            }
        default:
            return state
    }
}

export default helpVideosReducer
