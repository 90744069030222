import React, { Suspense, lazy } from 'react'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import BurgerMenu from './modules/BurgerMenu'
import { AppLoader } from './modules/components/AppLoader'
import MetaDecorator from './modules/components/MetaDecorator'
import ListContextProvider from './modules/contexts/ListContext'
import CartContextProvider from './modules/contexts/CartContext'
import rootReducer from './store/reducers/index'

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk)
  )
)

const Warning = lazy(() => import('./modules/components/Warning'))
const Home = lazy(() => import('./modules/pages/Home'))
const Videos = lazy(() => import('./modules/pages/Videos'))
const Photos = lazy(() => import('./modules/pages/Photos'))
const MyList = lazy(() => import('./modules/pages/MyList'))
const MyCart = lazy(() => import('./modules/cart/Cart'))
const Contact = lazy(() => import('./modules/pages/Contact'))
const Usc2257 = lazy(() => import('./modules/components/Usc2257'))
const Custrec = lazy(() => import('./modules/components/Custrec'))
const About = lazy(() => import('./modules/pages/About'))
const Terms = lazy(() => import('./modules/pages/Terms'))
const Faqs = lazy(() => import('./modules/pages/Faqs'))
const Membership = lazy(() => import('./modules/pages/Membership'))
const Dvd = lazy(() => import('./modules/pages/Dvd'))
// const Altcoin = lazy(() => import('./pages/Altcoin'))
const Detail = lazy(() => import('./modules/pages/Detail'))
const Footer = lazy(() => import('./modules/Footer'))
const ThankYou = lazy(() => import('./modules/cart/ThankYou'))
const Letter = lazy(() => import('./modules/components/banned/Letter'))
const HelpVidz = lazy(() => import('./modules/pages/HelpVids'))
const Search = lazy(() => import('./modules/pages/Search'))
const Application = lazy(() => import('./modules/pages/Application'))
// const Contest = lazy(() => import('./pages/Contest'))

// FIX FOR ROUTER PROBLEMS WHEN DEPLOYING APP!
// https://dev.to/crishanks/deploy-host-your-react-app-with-cpanel-in-under-5-minutes-4mf6

const App = () => {
  return (
    <Provider store={store}>
      <MetaDecorator />
      <CartContextProvider>
        <ListContextProvider>
          <Suspense fallback={<AppLoader />}>
            <BurgerMenu />
            <main>
              <div>
                <Routes>
                  <Route path="/" element={<Home />} exact />
                  {/* <Route path="/contest" element={<Contest/>} /> */}
                  <Route path="/warning" element={<Warning />} />
                  <Route path="/videos" element={<Videos />} />
                  <Route path="/photos" element={<Photos />} />
                  <Route path="/mylist" element={<MyList />} />
                  <Route path="/cart" element={<MyCart />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/usc2257" element={<Usc2257 />} />
                  <Route path="/custrec" element={<Custrec />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/faqs" element={<Faqs />} />
                  <Route path="/membership" element={<Membership />} />
                  <Route path="/letter" element={<Letter />} />
                  <Route path="/dvd" element={<Dvd />} />
                  <Route path="/thankyou/:id" element={<ThankYou />} />
                  <Route path="/order/:id" element={<ThankYou />} />
                  <Route path="/thankyou" element={<ThankYou />} />
                  <Route path="/order" element={<ThankYou />} />
                  <Route path="/helpVidz" element={<HelpVidz />} />
                  <Route path='/search' element={<Search />} />
                  <Route path='/application' element={<Application />} />
                  <Route path="/:id" element={<Detail />} />
                </Routes>
              </div>
            </main>
            <Footer />
          </Suspense>
        </ListContextProvider>
      </CartContextProvider>
    </Provider>
  )
}

export default App
